import React, { Component } from "react";
// import PropTypes from "prop-types";
import { Link } from "gatsby";
import Layout from "../components/Layout";

export default class IndexPage extends Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <section className="hero is-fullheight">
          <div className="hero-body ">
            <div className="container has-text-centered">
              <h1 className="hp--headline">
                <div className="hp__line">
                  <Link className="hp__link" to="/contact">
                    Dausign
                  </Link>
                </div>
                <div className="hp__line">
                  <Link className="hp__link" to="/work">
                    Work
                  </Link>
                  <Link className="hp__link" to="/about">
                    About
                  </Link>
                </div>
                <div className="hp__line">
                  <Link className="hp__link" to="/blog">
                    Blog
                  </Link>
                  <Link className="hp__link" to="/contact">
                    Contact
                  </Link>
                </div>
                <div className="hp__line">
                  <Link className="hp__link" to="/about">
                    Dan Au
                  </Link>
                </div>
              </h1>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

// IndexPage.propTypes = {
//   data: PropTypes.shape({
//     allMarkdownRemark: PropTypes.shape({
//       edges: PropTypes.array,
//     }),
//   }),
// }

// export const pageQuery = graphql`
//   query IndexQuery {
//     allMarkdownRemark(
//       sort: { order: DESC, fields: [frontmatter___date] },
//       filter: { frontmatter: { templateKey: { eq: "blog-post" } }}
//     ) {
//       edges {
//         node {
//           excerpt(pruneLength: 400)
//           id
//           fields {
//             slug
//           }
//           frontmatter {
//             title
//             templateKey
//             date(formatString: "MMMM DD, YYYY")
//           }
//         }
//       }
//     }
//   }
// `
